<template>
  <b-card>
    <g-table
      ref="branches-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true , permission: 'addBranches' }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          this.$router.push({ name: 'branch-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editBranches'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteBranches'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('name'), sortable: true },
        { key: 'notes', label: this.$t('notes'), sortable: true },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    // this.getPolicies();
    this.getItems();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPolicies() {
      this.get({ url: 'Policies' }).then((data) => {
        this.Policies = data;
      });
    },
    getItems() {
      this.get({ url: 'Branches' }).then((data) => {
        this.items = data;
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Branches', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'branch-edit',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
